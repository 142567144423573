import React from "react";

const Education = ({ degree, institution, year }) => {
  return (
    <React.Fragment>
      {" "}
      <h2>
        <strong>{!degree ? "Hardcoed Degree" : degree}</strong>
        <span>{!year ? "Hardcoded year" : year}</span>
      </h2>
      <span style={{ fontStyle: "italic", fontSize: "100%" }}>
        {!institution ? "Hardcoded institution" : institution}
      </span>
      <br />
      <hr
        style={{
          height: "1px",
          border: "none",
          backgroundColor: "rgba(105, 186, 213, 0.5)",
          marginTop: "5%",
        }}
      />
    </React.Fragment>
  );
};

export default Education;
