import React, { useEffect, useState } from "react";
import { Container, Col } from "react-bootstrap";
import "./reactiveResume.css";
import "./resume.css";
import $ from "jquery";
import Education from "./Education";
import Experience from "./Experience";
import ContactInfo from "../../Elements/ContactInfo";

const Resume = () => {
  const resumeData = require("./resumeData");

  const [experienceData, setExperienceStateData] = useState([]);
  const [educationData, setEducationData] = useState([]);

  useEffect(() => {
    setExperienceStateData(resumeData.experienceData);
    setEducationData(resumeData.educationData);
  }, []);

  useEffect(() => {
    $(".navbar-fading-effect").css("background", "rgba(4, 1, 130, 1)"); // starting color

    window.onscroll = () => {
      var scrollStatus = $(window).scrollTop();

      if (scrollStatus > 115) {
        $(".navbar-fading-effect").css("background", "rgba(4, 1, 130, .6)"); // changes to...
        $(".navbar-fading-effect").css("transition", ".7s");
      } else {
        $(".navbar-fading-effect").css("background", "rgba(4, 1, 130, 1)");
      }
    };
  });

  return (
    <React.Fragment>
      <div id="resume-background">
        <Container>
          <div id="resume" style={{ paddingTop: "3%" }}>
            <img
              src="http://www.alfredorafael.com/wp-content/uploads/2019/07/FullProfessionalPictureCropped.png"
              alt="Alfredo Pabon"
              className="professional-picture"
            />
            <h1>Alfredo R. Pabón</h1>
            <ContactInfo />

            {/* ////////////////////////////////////////////////////////////////////////////////////////////////////// */}

            <br />
            <dl id="skillset">
              <dt>SKILLS SET</dt>
              <dd>
                <h2>
                  <strong>Software Development</strong>
                </h2>
                <br />

                <div className="2skillColumns" style={{ display: "flex" }}>
                  <Col className="skillColumn1" style={{ flex: "1" }}>
                    <p>
                      <i
                        className="devicon-javascript-plain"
                        style={{ fontSize: "250%", color: "black" }}
                      />{" "}
                      JavaScript
                    </p>
                    <p>
                      <i
                        className="devicon-react-plain"
                        style={{ fontSize: "250%", color: "black" }}
                      />{" "}
                      React.js;
                    </p>
                    <p>
                      <i
                        className="devicon-nextjs-plain colored"
                        style={{ fontSize: "260%", color: "black" }}
                      />{" "}
                      Next.js;
                    </p>
                    <p>
                      <i
                        className="devicon-git-plain"
                        style={{ fontSize: "250%", color: "black" }}
                      />{" "}
                      Git;
                    </p>
                  </Col>

                  <Col className="skillColumn2" style={{ flex: "1" }}>
                    <p>
                      <i
                        className="devicon-typescript-plain"
                        style={{ fontSize: "245%", color: "black" }}
                      />{" "}
                      TypeScript;
                    </p>
                    <p>
                      <i
                        className="devicon-tailwindcss-plain"
                        style={{
                          fontSize: "250%",
                          color: "black",
                        }}
                      />{" "}
                      Tailwind CSS;
                    </p>
                    <p>
                      <i
                        className="devicon-wordpress-plain"
                        style={{ fontSize: "250%", color: "black" }}
                      />{" "}
                      WordPress;
                    </p>
                    <p>
                      <i
                        className="devicon-github-plain"
                        style={{ fontSize: "250%", color: "black" }}
                      />{" "}
                      Github;
                    </p>
                  </Col>
                </div>

                <hr
                  style={{
                    height: "1px",
                    border: "none",
                    backgroundColor: "rgba(105, 186, 213, 0.5)",
                  }}
                />
              </dd>
            </dl>

            <dl>
              <dt>EXPERIENCE</dt>
              <dd>
                {/* // Experience /////////////////////////////////////////////// */}

                {experienceData.map((item, i) => {
                  return (
                    <Experience
                      position={item.position}
                      date={item.date}
                      company={item.company}
                      duties={item.duties.map((duty, i) => (
                        <li key={i}>{duty}</li>
                      ))}
                    />
                  );
                })}

                <h2>
                  <strong>CBRN Specialist</strong>
                  <span>1-296 C/Co, 2004 – 2011</span>
                </h2>
                <span style={{ fontStyle: "italic", fontSize: "100%" }}>
                  US Army National Guard
                </span>
                <br />
                <br />
                <ul>
                  <li>
                    Provided training on chemical-defense readiness as Sergeant
                    E-5, NCOIC. Experienced in tactical movement, strategic
                    firearms use and security operations. Overseas deployments
                    as follows:
                    <br />
                  </li>
                  <ul>
                    <li>
                      <strong>
                        Multinational Force and Observers – Egypt; 2006
                      </strong>
                      <br />
                      <div className="deployment-details">
                        <ul>
                          <li>
                            Ensured implementation of Egypt and Israel Peace
                            Treaty's security provisions
                          </li>
                          <li>
                            Patrolled the Egyptian-Israel's northern border,
                            providing surveillance across MFO observation posts.{" "}
                          </li>
                        </ul>
                      </div>
                    </li>

                    <li>
                      <strong>NATO KFOR: Camp Bondsteel - Kosovo; 2009</strong>
                      <br />
                      <div className="deployment-details">
                        <ul>
                          <li>
                            Patrolled Serbian-Kosovo border in NATO-led
                            peacekeeping operation responsible for establishing
                            Kosovo's secure environment
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </ul>
                <hr
                  style={{
                    height: "1px",
                    border: "none",
                    backgroundColor: "rgba(105, 186, 213, 0.5)",
                    marginTop: "3%",
                  }}
                />
              </dd>
            </dl>
            <dl>
              <dt id="education">EDUCATION</dt>
              <dd style={{ paddingBottom: "0" }}>
                {educationData.map((item, i) => (
                  <Education
                    degree={item.degree}
                    institution={item.institution}
                    year={item.year}
                  />
                ))}
              </dd>
            </dl>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Resume;

{
  /*Return to the original blog article: "http://thenewcode.com/553/Build-A-Responsive-Web-Résumé"> */
}
