import React from "react";
import { Container } from "react-bootstrap";
import SubNav from "../../../Elements/SubNav";
import PicWithText from "../../../Elements/PicWithText";

const BackToRunning = () => {
  return (
    <span id="running">
      <SubNav
        title="Blog"
        // img="http://www.alfredorafael.com/wp-content/uploads/2019/02/whileLogoName-e1551079673184.png"
      />
      <Container>
        <h1>Back to Running</h1>
        <br />
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia
          accusantium, ad doloremque sit ipsa corrupti et beatae deleniti
          nostrum velit, in officiis error neque earum ex vel temporibus rem.
          Facere?
          <PicWithText
            side={"right"}
            picUrl="http://www.alfredorafael.com/wp-content/uploads/2021/06/running-foto-m.jpg"
            caption={"Something short about this pic"}
            text={
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris
                ut neque in mauris porta consectetur. Proin lorem metus,
                ullamcorper ut pretium in, fringilla ut felis. In hac habitasse
                platea dictumst. Mauris id lectus quam. Duis nibh quam, varius
                quis quam sed, pharetra hendrerit urna. Donec sit amet nunc a
                enim sodales cursus. Quisque vitae diam lorem. Praesent
                fermentum pulvinar purus, sollicitudin tincidunt felis fringilla
                id. Vestibulum condimentum bibendum posuere. Donec blandit
                ligula diam, quis mollis enim dictum vitae. Orci varius natoque
                penatibus et magnis dis parturient montes, nascetur ridiculus
                mus. Vivamus mattis aliquam consectetur. Nulla metus neque,
                tincidunt sed nibh non, vehicula laoreet dolor. Sed lobortis
                justo in tellus rutrum elementum. Sed dapibus nisi ac nisi
                consectetur, in fermentum turpis hendrerit.
              </p>
            }
          />
        </p>
      </Container>
    </span>
  );
};
export default BackToRunning;
